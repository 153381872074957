<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    // PageHeader
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
      },
    }
  },
  mounted() {},
  methods: {
    async formSubmit() {
      const data = new FormData()
      let img = document.querySelector('#form-image')
      let video = document.querySelector('#form-video')

      video = video.files['0']
      img = img.files['0']

      data.append('image', img)
      data.append('video', video)
      data.append('name', this.form.name)
      data.append('description', this.form.description)

      await axios
        .post('/api/course/create', data)
        .then((result) => {
          // this.$router.push('/contents')
        })
        .catch((err) => {
          alert(err.response.data.error.message)
        })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Create Video Thai Fighter</h4>
      </div>
      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <label>Video</label>

                    <b-form-group label-cols-lg="0">
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather type="video" class="icon-dual icon-xs align-middle"></feather>
                        </b-input-group-prepend>
                        <b-form-file id="form-video" accept="video/*"></b-form-file>
                      </b-input-group>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        placeholder="Enter title..."
                        v-model="form.name"
                        name="title"
                        type="text"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <router-link to="/contents">
                        <button
                          type="button"
                          class="btn btn-danger width-lg rounded-pill aligncenter float-left"
                        >
                          <feather type="x"></feather>Cancel
                        </button>
                      </router-link>
                      <button
                        type="submit"
                        class="btn btn-primary width-lg rounded-pill aligncenter float-right"
                      >
                        <feather type="file-plus"></feather>Create
                      </button>
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
